import React from "react"
import Layout from "../components/layout"
import Seo from '../components/seo'

export default function page404() {
  return <Layout>
    <Seo title="404" />
    <section id="hero" className="hero hero-404">
      <div className="container">
        <div className="margin-about" />
        <div className="hero-text-container">
          <div className="purple-404">404 error</div>
          <h1>Oops! We can't find the page you're looking for.</h1>
          <a href="/" className="btn btn-outline btn-hero">
            <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#5720CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Go back to the frontpage
          </a>
        </div>
      </div>
    </section>
  </Layout>
}